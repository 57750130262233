import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js';
import { Box, Button, Flex, Text, Heading, Image, Stack, SimpleGrid, } from '@chakra-ui/react'
import LayoutFunnel from "../components/layouts/layoutFunnel.js"

import withLocation from "../utils/withLocation"
import portrait from '../images/portraitOlivierL.jpg'
import axios from 'axios'
import Seo from '../components/Seo/';

import { FormattedMessage } from 'react-intl';
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_CLIENT_KEY);

const siteConfig = require('../../config')

const title = {
    "fr": "Souscription à l'abonnement site pro par Wanaboat"
    ,
    "en": "Souscription à l'abonnement site pro par Wanaboat"
}
const description = {
    "fr": "Si vous souhaitez faire un petit don pour le fonctionnement du service, c'est ici."
    ,
    "en": "If you wish to make a small donation for the operation of the service, it is here."
};

const proSubscribe = (props) => {

    console.log(props, props.search.price)

    const handleGetSessionId = async (event) => {

        const stripePromise = loadStripe('pk_test_51HgYtgHASE97lMmZHppLE9N8ZEMa7DEPg99MIhdQTKhDmPgr3vOZvBfCE2EYsMZmSOo19ubxzbO1NnorTUXzWS9Q00QcTWHXCn');

        // const stripePromise = loadStripe('pk_test_51HgYtgHASE97lMmZHppLE9N8ZEMa7DEPg99MIhdQTKhDmPgr3vOZvBfCE2EYsMZmSOo19ubxzbO1NnorTUXzWS9Q00QcTWHXCn');

        // Get Stripe.js instance
        const stripe = await stripePromise;

        // Call your backend to create the Checkout Session
        const response = await fetch(`http://192.168.1.17:9000/stripe/pro-subscription/`, { method: 'POST' });

        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };

    return (
        <LayoutFunnel>
            <Seo
                title={title[process.env.GATSBY_LANG]}
                description={description[process.env.GATSBY_LANG]}
                slug={props.pageContext.slug}
            />
            <Flex
                justifyContent="center"
                alignItems="center"
                minH='100vh'
            >
                <Stack
                    maxW='740px'
                    spacing='1.5rem'
                    px='1rem'
                    py={{ base: '2rem', lg: '4rem' }}
                    background='white'
                >
                    <Heading
                        as="h1"
                        // fontSize="42px"
                        fontSize={{ base: '3xl', lg: '5xl' }}
                        color="blue.brand"
                    >
                        {/* <FormattedMessage id='donation.title' />&nbsp;? */}
                        Souscription à l'abonnement site pro Wanaboat
                    </Heading>
                    <Image
                        src={portrait}
                        alt="Le Capitaine de Wanaboat"
                    />
                    <Text
                        color="gray.700"
                        fontSize="18px"
                    >
                        <FormattedMessage id='donation.notice.1' />
                    </Text>
                    <Text
                        fontSize='15px'
                        fontWeight='500'><FormattedMessage id='donation.will.be.used' />
                        <Text as="span" color="gray.600" fontWeight='400'>
                            <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                            <FormattedMessage id='donation.will.pay.charges' />
                            <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                            <FormattedMessage id='donation.will.upgrade.service' />
                        </Text>
                    </Text>
                    <Box columns="3" gap="1rem">
                        <Box
                            border='solid 2px'
                            borderColor='blue.brand'
                            boxShadow='md'
                            p={{ base: '.5rem', lg: '1rem' }}
                            textAlign='center'
                            borderRadius='lg'
                            cursor='pointer'
                            transition='transform 250ms ease'
                            onClick={() => handleGetSessionId(props.search.price)}
                            _hover={{
                                transform: 'scale3d(1.1,1.1,1.1)',
                                backgroundColor: 'blue.50'
                            }}
                        >
                            <Text color="blue.brand" fontWeight="bold" fontSize="24px">
                                Accéder à la page de souscription Stripe
                            </Text>
                        </Box>
                    </Box>
                </Stack>
            </Flex>
        </LayoutFunnel>
    )

}

export default withLocation(proSubscribe)